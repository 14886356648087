import theme from 'components/theme';
import { getWebPageSchema } from 'functions/schemaHelpers';
import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { JsonLd } from 'react-schemaorg';
import CMSBlocks from '../../cms';
import { CircularProgress } from '@material-ui/core';
import { TrackingContext } from '../../providers/tracking';
import { SessionContext } from '../../providers/session';
import CategoryMeta from './meta';

const useStyles = makeStyles(theme => ({
	wrapper: {
		backgroundColor: theme.palette.primary.background
	},
	isLoading: {
		opacity: 0.75
	},
	loader: {
		position: 'fixed',
		left: '50%',
		top: '50%',
		marginLeft: -20,
		zIndex: 1000
	}
}));

function CMS({ appData, cmsPage, category, categoryMenu, isLoading, canonicalPathInfo, breadcrumb }) {
	const { trackingInitiated, actions } = useContext(TrackingContext);
	const { config } = useContext(SessionContext);
	const classes = useStyles();

	const schemaData = {
		appData,
		category,
		breadcrumb,
		name: appData?.meta?.name || theme.logo.alt,
		metaTitle: category?.metaTitle || category?.name,
		description:
			category?.metaDescription ||
			category?.description ||
			appData?.meta?.description ||
			'Explore a wide range of high-quality furniture for your home.',
		keywords: appData?.meta?.keywords || 'furniture, home decor, living room, bedroom, dining',
		baseUrl: appData?.meta?.basePath || 'https://newport.se',
		languageCode: appData?.meta?.language?.languageCode || 'sv-SE'
	};

	useEffect(() => {
		if (category && !isLoading && trackingInitiated) {
			actions.pageView(category.metaTitle || category.translated.name, 'cms');
		}
	}, [isLoading, trackingInitiated]);

	function getSalesChannelClass() {
		let salesChannel = 'sv';

		switch (config.language.locale) {
			case 'sv':
				salesChannel = 'se';
				break;
			case 'fi':
				salesChannel = 'fi';
				break;
			case 'nb':
				salesChannel = 'no';
				break;
			case 'da':
				salesChannel = 'dk';
				break;
			case 'nl':
				salesChannel = 'nl';
				break;
			case 'en':
				if(config.language.languageCode === 'en-US') {
					salesChannel = 'us';
				} else if (config.language.languageCode === 'en-GB') {
					salesChannel = 'gb';
				} else {
					salesChannel = 'eu';
				}
				break;
		}

		return salesChannel;
	}

	return (
		<div className={'cms-page'}>
			<CategoryMeta category={category} canonicalPath={canonicalPathInfo} />
			<JsonLd item={getWebPageSchema(schemaData)} />
			<div
				className={[
					classes.wrapper,
					isLoading ? classes.isLoading : '',
					getSalesChannelClass(),
					'cms-page-primary'
				].join(' ')}
				style={{ flexGrow: 1 }}
			>
				{isLoading ? <CircularProgress className={classes.loader} /> : ''}
				<CMSBlocks cmsPage={cmsPage} category={category} menu={appData.menu} breadcrumbs={breadcrumb} categoryMenu={categoryMenu} />
			</div>
		</div>
	);
}

export default CMS;
